import * as grid from '../styles/grid.module.scss';
import * as styles from "../styles/vippeloft.module.scss";
import ArticleLayout from "../components/articleLayout/ArticleLayout";
import PageTitle from "../components/contentHeadings/PageTitle";
import Quote from "../components/quote/Quote";
import React from 'react';
import SeeAvailableButton from "../components/button/SeeAvailableButton";
import Seo from "../components/Seo";
import SubHeading from "../components/contentHeadings/SubHeading";
import { Link } from "gatsby";
import {StaticImage} from "gatsby-plugin-image";
import outputPixelDensities from "../data/defaultPixelDensities";

const VippeloftPage = () => {

    const img1 = <StaticImage
        src={"../images/vippeloft/vippeloft2.jpg"}
        alt={"Før-/etterbilde av ansikt som viser effekten av vippeløft med farge"}
        width={960}
        sizes={"(min-width: 960px): 329px, (min-width: 600px): calc(50vw - 32px), 100vw - 40px"}
        outputPixelDensities={outputPixelDensities}
    />;

    // const img2 = <StaticImage
    //     src={"../images/vippeloft/vippeloft4.jpg"}
    //     alt={"Før-/etterbilde av ansikt som viser effekten av vippeløft med farge"}
    //     width={960}
    //     sizes={"(min-width: 960px): 329px, (min-width: 600px): calc(50vw - 32px), 100vw - 40px"}
    //     outputPixelDensities={outputPixelDensities}
    // />; Kan ikke bruke denne - se epost

    const img3 = <StaticImage
        src={"../images/vippeloft/vippeloft3.jpg"}
        alt={"Før-/etterbilde av ansikt som viser effekten av vippeløft med farge"}
        width={960}
        sizes={"(min-width: 960px): 329px, (min-width: 600px): calc(50vw - 32px), 100vw - 40px"}
        outputPixelDensities={outputPixelDensities}
    />;

    const img4 = <StaticImage
        src={"../images/vippeloft/vippeloft5.jpg"}
        alt={"Før-/etterbilde av ansikt som viser effekten av vippeløft med farge"}
        width={960}
        sizes={"(min-width: 960px): 329px, (min-width: 600px): calc(50vw - 32px), 100vw - 40px"}
        outputPixelDensities={outputPixelDensities}
    />;

  //   const images = useStaticQuery(graphql`
  //       query {
  //         img1: file(relativePath: { eq: "vippeloft/vippeloft2.jpg" }) {
  //           childImageSharp {
  //             fluid(maxWidth: 500, quality: 80, sizes: "(min-width: 960px): 329px, (min-width: 600px): calc(50vw - 32px), 100vw - 40px") {
  //               ...GatsbyImageSharpFluid_withWebp
  //             }
  //           }
  //         },
  //         img2: file(relativePath: { eq: "vippeloft/vippeloft4.jpg" }) {
  //           childImageSharp {
  //             fluid(maxWidth: 500, quality: 80, sizes: "(min-width: 960px): 329px, (min-width: 600px): calc(50vw - 32px), 100vw - 40px") {
  //               ...GatsbyImageSharpFluid_withWebp
  //             }
  //           }
  //         },
  //         img3: file(relativePath: { eq: "vippeloft/vippeloft3.jpg" }) {
  //           childImageSharp {
  //             fluid(maxWidth: 500, quality: 80, sizes: "(min-width: 960px): 329px, (min-width: 600px): calc(50vw - 32px), 100vw - 40px") {
  //               ...GatsbyImageSharpFluid_withWebp
  //             }
  //           }
  //         },
  //         img4: file(relativePath: { eq: "vippeloft/vippeloft5.jpg" }) {
  //           childImageSharp {
  //             fluid(maxWidth: 500, quality: 80, sizes: "(min-width: 960px): 329px, (min-width: 600px): calc(50vw - 32px), 100vw - 40px") {
  //               ...GatsbyImageSharpFluid_withWebp
  //             }
  //           }
  //         }
  //       }
  // `);

    return(
        <ArticleLayout>
            <Seo title={'Vippeløft'} description={"Vippeløft er en permanent bøy av dine naturlige øyevipper. Når vippene blir bøyd oppover blir det en illusjon av lengre vipper og mer våkne øyne. Vippeløft varer i 4-8 uker og blir gradvis borte."}/>
            <PageTitle>Hva er vippeløft/vippebøy?</PageTitle>
            <Quote>Vippeløft er en permanent bøy av dine naturlige øyevipper. Når vippene blir bøyd oppover blir det en illusjon av lengre vipper og mer våkne øyne.</Quote>
            <figure>
                {/*<Img fluid={images.img2.childImageSharp.fluid}*/}
                {/*     alt={"Før-/etterbilde av ansikt som viser effekten av vippeløft med farge"}*/}
                {/*/>*/}
                { img3 }
            </figure>
            <SubHeading className={styles.topMargin}>Hvordan er behandlingen?</SubHeading>
            <div className={grid.container}>
                <div className={grid.xs12Sm6P8}>
                    <p>Prosessen begynner med at vi legger en pad/tape over undervippene slik at disse ikke får krem på seg. Vi velger så ut en form som passer til dine naturlige vipper, og som vil gi best mulig effekt. </p>
                    <p>Vi bruker så kremer som får vippene til å få samme fasong som formen. Hvor lenge kremene skal ligge på kommer an på dine naturlige vipper. </p>
                    <p>Hvis vi skal farge vippene gjør vi dette helt til sist. Vi har flere farger å velge mellom, alt fra lys brun til blåsvart. Les mer om farging nedenfor.</p>
                    <p>Du kan beregne at vippeløftbehandlingen tar ca. 30 minutter. For vippeløft med farge setter vi av 60 minutter, men det tar ofte kortere tid enn dette. </p>
                </div>
                <div className={grid.xs12Sm6P8}>
                    <figure>
                        {/*<Img fluid={images.img1.childImageSharp.fluid}*/}
                        {/*     alt={"Før-/etterbilde av ansikt som viser effekten av vippeløft med farge"}*/}
                        {/*/>*/}
                        { img1 }
                        <figcaption>Vippeløft med farge</figcaption>
                    </figure>
                </div>
            </div>
            <SeeAvailableButton>Se ledige timer her</SeeAvailableButton>
            <SubHeading>Hvor lenge varer vippeløft?</SubHeading>
            <p>Vippeløft varer i 4-8 uker og blir gradvis borte. Normalt holder vippeløft i ca 6 uker. Vippene bytter seg ut hver dag, og de nye vippene dine vil ha din naturlige bøy. Effekten vil derfor gradvis forsvinne. De nye vippene som vokser ut vil være veldig korte i begynnelsen, så overgangen vil være myk.</p>
            <p>Det som er flott med vippeløft er at du kan behandle vippene akkurat som normalt! Vippeløft er helt vedlikeholdsfritt og du vil beholde bøyen helt til vippene har byttet seg ut.</p>
            <figure>
                {/*<Img fluid={images.img4.childImageSharp.fluid}*/}
                {/*     alt={"Før-/etterbilde av ansikt som viser effekten av vippeløft med farge"}*/}
                {/*/>*/}
                { img4 }
            </figure>
            <SubHeading>Vippeløft og farge</SubHeading>
            <p>En god kombinasjon er vippeløft og farging. Da vil effekten bli tydeligere, fordi man ser hårene bedre. De fleste har lysere vipper ytterst og fargen vil få hele vippen til å synes. Vi har en pakkeløsning med både farge og vippeløft.</p>
            <p>Vi bruker Fred Hamelten-farger som sitter svært godt og er av høy kvalitet. </p>
            <p>Vi har en service som heter vippeløft med farge for to personer hvor to kunder kommer samtidig, og vi tar vippeløft + farge på begge samtidig. Dette er en svært populær behandling som vi tilbyr i salongen vår i sentrum. Behandlingen tar en time. Dette kan bookes som en egen time, så ta med deg din beste venn og bli vippefine sammen!</p>
            <p>Usikker på om du vil ha vippeløft eller extensions? Les om forskjellene <Link to={'/vippelft-vs-vippeextensions'}>HER</Link>. </p>
            <SubHeading>Kan man bruke sminke?</SubHeading>
            <p>Ingen vanlige kosmetiske produkter vil påvirke løftet, så mascara, vippeserum og sminke går helt fint! Det er dine egne naturlige vipper fremdeles,
                'de er bare bøyd i stedet for å være rette.</p>
            <SubHeading>Når kan man ta vippeløft på nytt?</SubHeading>
            <p>Du bør vente til bøyen har forsvunnet før du tar vippeløft på nytt, ellers vil du kunne få for mye bøy. Det varierer fra person til person hvor fort vippene vokser, så prøv gjerne å se selv om det fremdeles er den del bøy igjen. Noen må også vente lenger enn seks uker. Vi vet at man blir fort hekta på vippeløft, men hvis vippene fremdeles er bøyd vil vi helst ikke bøye dem enda mer.</p>
            <SubHeading>Kan man være allergisk mot vippeløft?</SubHeading>
            <p>Vippeløft-produktene vi bruker er allergivennlige, men allergi mot vippefargen kan forekomme. Dette er sjeldent, men hvis du er i tvil, så be oss om en gratis allergitest. Hvis du har reagert på vippe-, bryns- eller hårfarge tidligere bør du ta kun ta alminnelig vippeløft, uten farge. </p>
        </ArticleLayout>
    )
};

export default VippeloftPage;